


























































































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {DimensionReductionResult} from "@/models/DimensionReductionResult";
import DimensionReductionResult2Plotly from "@/components/DimensionReductionResult2Plotly.vue";
import {DataAnalyseResponse} from "@/api/response/DataAnalyseResponse";

@Component({
  components: {
    DimensionReductionResult2Plotly
  }
})
export default class DataAnalysisUMAP extends Vue {
  private inputFile: File | null = null;
  private dialog = false;
  private results: DimensionReductionResult[] = [
    {"name": "A", "dimensions": [3.270040512084961, 2.3871846199035645]},
    {"name": "B", "dimensions": [0.5025748014450073, 7.877153396606445]},
    {"name": "C", "dimensions": [-0.1328352391719818, 5.9013566970825195]},
    {"name": "D", "dimensions": [3.095029830932617, 2.2152745723724365]},
    {"name": "E", "dimensions": [6.5157270431518555, 11.473583221435547]},
    {"name": "F", "dimensions": [2.957249164581299, 2.0789597034454346]},
    {"name": "G", "dimensions": [0.7292130589485168, 7.390415668487549]},
    {"name": "H", "dimensions": [5.620652198791504, 11.444807052612305]},
    {"name": "I", "dimensions": [-0.3775598108768463, 6.2517242431640625]},
    {"name": "J", "dimensions": [0.9185118079185486, 7.867730140686035]},
    {"name": "K", "dimensions": [0.8181599378585815, 6.338676929473877]},
    {"name": "L", "dimensions": [5.979860305786133, 11.081281661987305]},
    {"name": "M", "dimensions": [0.6432993412017822, 6.762760639190674]},
    {"name": "N", "dimensions": [0.10981754213571548, 6.640400409698486]},
    {"name": "O", "dimensions": [6.0402512550354, 11.730643272399902]}
  ];
  private dimensionCount = 0;
  private nNeighbors = 3;

  private isActive = false;
  private startAnalysis = false;
  private loading = false;

  private send() {
    if (this.inputFile) {
      this.results = [];
      this.startAnalysis = true;
      this.loading = true;
      const data = new FormData();
      data.append("file", this.inputFile);
      data.append("dimension_count", `${this.dimensionCount + 2}`);
      data.append("n_neighbors", `${this.nNeighbors}`);
      Api.call<DataAnalyseResponse>(ApiDefConstants.DATA_UMAP(), data).then((res: AxiosResponse<DataAnalyseResponse>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  }
}
