


















































































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {DimensionReductionResult} from "@/models/DimensionReductionResult";
import DimensionReductionResult2Plotly from "@/components/DimensionReductionResult2Plotly.vue";
import {DataAnalyseResponse} from "@/api/response/DataAnalyseResponse";

@Component({
  components: {
    DimensionReductionResult2Plotly
  }
})
export default class DataAnalysisPCA extends Vue {
  private inputFile: File | null = null;
  private dialog = false;
  private results: DimensionReductionResult[] = [
    {"name": "A", "dimensions": [-3.545409320675622, -2.8773729938881267, -0.7966211343314824]},
    {"name": "B", "dimensions": [6.698863799694109, 0.650335094531058, 0.8889089015924722]},
    {"name": "C", "dimensions": [0.16494841004806235, 1.2233667459324389, 1.1253013283131126]},
    {"name": "D", "dimensions": [-3.9448077140928204, -3.086914465204465, 0.7525216154125179]},
    {"name": "E", "dimensions": [-1.3617701731471108, 6.279254064350757, -0.4212789703386627]},
    {"name": "F", "dimensions": [-2.660437082623906, -2.676675608035452, -0.15330854616715603]},
    {"name": "G", "dimensions": [5.205802337419127, -1.0325502311466623, -0.8593890737288327]},
    {"name": "H", "dimensions": [-6.9518827574249595, 0.32590834632160987, 1.038510205692111]},
    {"name": "I", "dimensions": [1.130817833260181, -1.0902520719104853, 2.2336798448364865]},
    {"name": "J", "dimensions": [7.960871919672667, 1.1584301685953013, 0.3109366442669503]},
    {"name": "K", "dimensions": [2.1553896963340797, -1.7923095245799352, -1.814867581297975]},
    {"name": "L", "dimensions": [-5.12151643813518, 2.506098359175476, -0.03778758367793634]},
    {"name": "M", "dimensions": [2.722656364063932, -0.0625348155233376, -0.6288498784528544]},
    {"name": "N", "dimensions": [1.6008757408507512, -1.2484710818676905, -0.264391423577344]},
    {"name": "O", "dimensions": [-4.054402615243307, 1.7236880132495145, -1.3733643485414093]}
  ];
  private dimensionCount = 1;

  private isActive = false;
  private startAnalysis = false;
  private loading = false;

  private send() {
    if (this.inputFile) {
      this.results = [];
      this.startAnalysis = true;
      this.loading = true;
      const data = new FormData();
      data.append("file", this.inputFile);
      data.append("dimension_count", `${this.dimensionCount + 2}`);
      Api.call<DataAnalyseResponse>(ApiDefConstants.DATA_PCA(), data).then((res: AxiosResponse<DataAnalyseResponse>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  }

  //private get dialogPlotLayout() {

  //}

}
