









































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {AnalyseResponseBase} from "@/api/response/AnalyseResponseBase";
import {AnalyseResultBase} from "@/models/AnalyseResultBase";

@Component({
  components: {}
})
export default class LanguageSimilar extends Vue {
  private word = "学習";
  private tableHeaders = [
    {text: 'Name', value: "name"},
    {text: 'Score', value: "score"}
  ];
  private results = [
    {name: "教育", score: "0.7830594778060913"},
    {name: "自学", score: "0.7655531764030457"},
    {name: "メディアリテラシー", score: "0.7639021277427673"},
    {name: "アクティブラーニング", score: "0.7623758316040039"},
    {name: "ティームティーチング", score: "0.7608695030212402"}
  ] as AnalyseResultBase[];

  private startAnalysis = false;
  private loading = false;

  private send() {
    if (this.word) {
      this.startAnalysis = true;
      this.loading = true;
      Api.call<AnalyseResponseBase>(ApiDefConstants.LANGUAGE_SIMILAR_ANALYSE(), {word: this.word}).then((res: AxiosResponse<AnalyseResponseBase>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.results = [
          {name: "エラー", score: "0"},
        ]
        this.loading = false;
      });
    }
  }
}
