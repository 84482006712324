import ApiDef from "@/api/ApiDef";
import {Method} from "@/api/Method";
import ApiPathConstants from "@/constants/ApiPathConstants";

export default class ApiDefConstants {

  static readonly KERAS_IMAGE_ANALYSE = () => new ApiDef(
    Method.POST, ApiPathConstants.KERAS_IMAGE_ANALYSE(), {"Content-Type": "multipart/form-data"}
  );

  static readonly LANGUAGE_SIMILAR_ANALYSE = () => new ApiDef(Method.POST, ApiPathConstants.LANGUAGE_SIMILAR_ANALYSE());
  static readonly LANGUAGE_CALC_ANALYSE = () => new ApiDef(Method.POST, ApiPathConstants.LANGUAGE_CALC_ANALYSE());
  static readonly LANGUAGE_MORPHOLOGICAL = () => new ApiDef(Method.POST, ApiPathConstants.LANGUAGE_MORPHOLOGICAL());


  static readonly DATA_PCA = () => new ApiDef(
    Method.POST, ApiPathConstants.DATA_PCA(), {"Content-Type": "multipart/form-data"}
  );
  static readonly DATA_SVD = () => new ApiDef(
    Method.POST, ApiPathConstants.DATA_SVD(), {"Content-Type": "multipart/form-data"}
  );
  static readonly DATA_UMAP = () => new ApiDef(
    Method.POST, ApiPathConstants.DATA_UMAP(), {"Content-Type": "multipart/form-data"}
  );
  static readonly DATA_T_SNE = () => new ApiDef(
    Method.POST, ApiPathConstants.DATA_T_SNE(), {"Content-Type": "multipart/form-data"}
  );
}
