export default class PathConstants {
  public static createPath = (...params: (string | number)[]) => {
    //params.unshift(`/${PathConstants.BASE}`);
    return `/${params.join("/")}`;
  };
  private static createName = (name: string): string => {
    return `${PathConstants.BASE}-${name}`;
  };

  static readonly BASE = 'darwin';
  static readonly HOME = PathConstants.createPath('');
  static readonly HOME_NAME = PathConstants.createName('home');
  static readonly IMAGE_ANALYSIS = PathConstants.createPath('image-analysis');
  static readonly IMAGE_ANALYSIS_NAME = PathConstants.createName('image-analysis');
}
