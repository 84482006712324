





import {Component, Prop, Vue} from 'vue-property-decorator';
import {Plotly} from "vue-plotly";
import {DimensionReductionResult} from "@/models/DimensionReductionResult";

@Component({
  components: {
    Plotly
  }
})
export default class DimensionReductionResult2Plotly extends Vue {
  @Prop({default: [], required: true})
  private dimensionReductionResults?: DimensionReductionResult[];
  @Prop({default: () => ({with: undefined, height: undefined}), required: false})
  private plotLayout?: { with: number, height: number };

  private get plotData() {
    if (this.dimensionReductionResults) {
      const first = this.dimensionReductionResults[0];
      if (first) {
        if (first.dimensions.length == 2) {
          return this.dimensionReductionResults.map((d: DimensionReductionResult) => {
            return {
              x: [d.dimensions[0]],
              y: [d.dimensions[1]],
              type: "scatter",
              name: d.name,
              mode: "markers",
              text: d.name
            }
          });
        } else if (first.dimensions.length == 3) {
          return this.dimensionReductionResults.map((d: DimensionReductionResult) => {
            return {
              x: [d.dimensions[0]],
              y: [d.dimensions[1]],
              z: [d.dimensions[2]],
              type: "scatter3d",
              name: d.name,
              mode: "markers",
              text: d.name,
              marker: {
                size: 2
              }
            }
          });
        } else {
          return {}
        }
      } else {
        return {};
      }
    } else {
      return {};
    }
  }
}
