







































































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {AnalyseResponseBase} from "@/api/response/AnalyseResponseBase";
import {AnalyseResultBase} from "@/models/AnalyseResultBase";
import {WordCalc} from "@/models/WordCalc";

@Component({
  components: {}
})
export default class LanguageCalc extends Vue {
  private word = "総理";
  private addWords = [
    {word: "日本", operation: 1},
    {word: "アメリカ", operation: 0},
  ] as WordCalc[];
  private tableHeaders = [
    {text: 'Name', value: "name"},
    {text: 'Score', value: "score"}
  ];
  private results = [
    {name: "大統領", score: "0.7508231997489929"},
    {name: "首相", score: "0.743184506893158"},
    {name: "カルビン・クーリッジ", score: "0.7423067092895508"},
    {name: "ディーン・アチソン", score: "0.732529878616333"},
    {name: "ウィリアム・スワード", score: "0.7288525104522705"},
    {name: "国務", score: "0.7260829210281372"},
    {name: "マデレーン・オルブライト", score: "0.7256097793579102"},
    {name: "マクジョージ・バンディ", score: "0.7241974472999573"},
    {name: "エリフ・ルート", score: "0.7223106622695923"},
    {name: "ジェラルド・フォード", score: "0.7094321846961975"},
  ] as AnalyseResultBase[];

  private startAnalysis = false;
  private loading = false;

  private send() {
    if (this.word) {
      this.results = [];
      this.startAnalysis = true;
      this.loading = true;
      Api.call<AnalyseResponseBase>(ApiDefConstants.LANGUAGE_CALC_ANALYSE(), {
        positive: [this.word, ...this.addWords.filter(val => !!val.word).filter(val => val.operation == 0).map(val => val.word)],
        negative: [...this.addWords.filter(val => !!val.word).filter(val => val.operation == 1).map(val => val.word)]
      }).then((res: AxiosResponse<AnalyseResponseBase>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.results = [
          {name: "エラー", score: "0"},
        ]
        this.loading = false;
      });
    }
  }

  private add() {
    this.addWords.push({word: "", operation: 0});
  }
}
