import {AxiosRequestConfig, Method, ResponseType} from 'axios';

export const TEMPLATE_TAG = '{}';

export default class ApiDef {
  readonly method: Method;
  readonly url: string;
  readonly headers?: { [key: string]: string };
  responseType?: ResponseType;
  config: AxiosRequestConfig = {};

  private static queryExtractRegex = new RegExp('(.*)(\\?(.*=.*)+&?(.*=(.*)?)?)');

  constructor(method: Method, url: string, headers?: { [key: string]: string }, responseType?: ResponseType, config?: AxiosRequestConfig) {
    this.method = method;
    this.url = url;
    this.headers = Object.assign({}, headers ? headers : {});
    this.responseType = responseType;
    if (config !== void 0) {
      this.config = Object.assign({}, config);
    }
    this.config.withCredentials = true;
  }

  query(key: string, value: any): ApiDef {
    const tempUrl = this.url;
    const execs = ApiDef.queryExtractRegex.exec(tempUrl);
    if (execs == null) {
      return new ApiDef(this.method, `${tempUrl}?${key}=${(value === null || value === void 0) ? '' : value}`, this.headers, this.responseType, this.config);
    } else {
      return new ApiDef(this.method, `${tempUrl}&${key}=${(value === null || value === void 0) ? '' : value}`, this.headers, this.responseType, this.config)
    }
  }

  queries(obj: any): ApiDef {
    if (!obj) {
      return this;
    }
    let apiDef: ApiDef = this.clone();
    for (const key of Object.keys(obj)) {
      if (obj[key]) {
        if (Array.isArray(obj[key])) {
          (obj[key] as []).forEach(val => {
            apiDef = apiDef.query(key, val);
          });
        } else {
          apiDef = apiDef.query(key, obj[key]);
        }
      }
    }
    return apiDef;
  }

  configure(configurator: (config: AxiosRequestConfig) => void) {
    const def = this.clone();
    configurator(def.config);
    return def;
  }

  private clone() {
    return new ApiDef(this.method, this.url, this.headers, this.responseType, this.config);
  }
}
