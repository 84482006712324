import PathConstants from "@/constants/PathConstants";

export default class ApiPathConstants {
  private static createApiPath = (...params: (string | number)[]) => {
    params.unshift(ApiPathConstants.BASE);
    return PathConstants.createPath(...params);
  };

  static readonly BASE = 'api';

  static readonly KERAS_IMAGE_ANALYSE = () => ApiPathConstants.createApiPath("keras", "image");

  static readonly LANGUAGE_SIMILAR_ANALYSE = () => ApiPathConstants.createApiPath("language", "similar");
  static readonly LANGUAGE_CALC_ANALYSE = () => ApiPathConstants.createApiPath("language", "calc");
  static readonly LANGUAGE_MORPHOLOGICAL = () => ApiPathConstants.createApiPath("language", "morphological");

  static readonly DATA_PCA = () => ApiPathConstants.createApiPath("data", "pca");
  static readonly DATA_SVD = () => ApiPathConstants.createApiPath("data", "svd");
  static readonly DATA_UMAP = () => ApiPathConstants.createApiPath("data", "umap");
  static readonly DATA_T_SNE = () => ApiPathConstants.createApiPath("data", "t-sne");
}
