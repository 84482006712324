import Axios, {AxiosResponse} from "axios";
import ApiDef from "@/api/ApiDef";
import {ResponseBase} from "@/api/response/ResponseBase";

const axios = Axios;

export default class Api {
    private static rawAxios = axios;

    public static call<T extends ResponseBase>(_def: ApiDef, data?: any) {
        const def: ApiDef = _def;
        const requestConfig = {
            method: def.method,
            url: def.url,
            headers: def.headers,
            data,
            responseType: def.responseType,
            ...def.config
        };
        console.debug(`[API Request] method: ${requestConfig.method}, url: ${requestConfig.url}, data:`, requestConfig.data);
        return Api.rawAxios(requestConfig).then((res: AxiosResponse<T>) => {
            //if (!res.data.success) {
            //    console.log("here!");
            //}
            return res;
        });
    }

    // APIを呼び出してレスポンスをファイルに保存します。
    // public static download(def: ApiDef) {
    //     def.responseType = 'blob';
    //     return this.call(def)
    //         .then(response => {
    //             let link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(response.data);
    //             link.download = this.getFileName(response);
    //             link.click();
    //         });
    // }
    //
    // private static getFileName(response: any) {
    //     let filename = "";
    //     let disposition = response.headers['content-disposition'];
    //     if (disposition == void 0) {
    //         disposition = response.headers['Content-Disposition']
    //     }
    //     if (disposition) {
    //         let filenameRegex = /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/;
    //         let matches = filenameRegex.exec(disposition);
    //         if (matches != null && matches[2]) filename = matches[2].replace(/['"]/g, '');
    //     }
    //     return decodeURIComponent(filename);
    // }

    public static all: <T>(values: (Promise<T> | T)[]) => Promise<T[]> = Api.rawAxios.all;
    public static spread: <T, R>(callback: (...args: T[]) => R) => (array: T[]) => R = Api.rawAxios.spread;
}
