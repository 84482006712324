










































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {AnalyseResponseBase} from "@/api/response/AnalyseResponseBase";
import {AnalyseResultBase} from "@/models/AnalyseResultBase";

@Component({
  components: {}
})
export default class LanguageMorphological extends Vue {
  private sentence = "すもももももももものうち";
  private tableHeaders = [
    {text: 'Name', value: "name"},
    {text: 'Part of Speech', value: "score"}
  ];
  private results = [
    {name: "すもも", score: "名詞,一般,*,*,*,*,すもも,スモモ,スモモ"},
    {name: "も", score: "助詞,係助詞,*,*,*,*,も,モ,モ"},
    {name: "もも", score: "名詞,一般,*,*,*,*,もも,モモ,モモ"},
    {name: "も", score: "助詞,係助詞,*,*,*,*,も,モ,モ"},
    {name: "もも", score: "名詞,一般,*,*,*,*,もも,モモ,モモ"},
    {name: "の", score: "助詞,連体化,*,*,*,*,の,ノ,ノ"},
    {name: "うち", score: "名詞,非自立,副詞可能,*,*,*,うち,ウチ,ウチ"},
  ] as AnalyseResultBase[];

  private startAnalysis = false;
  private loading = false;

  private send() {
    if (this.sentence) {
      this.startAnalysis = true;
      this.loading = true;
      Api.call<AnalyseResponseBase>(ApiDefConstants.LANGUAGE_MORPHOLOGICAL(), {sentence: this.sentence}).then((res: AxiosResponse<AnalyseResponseBase>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.results = [
          {name: "エラー", score: "0"},
        ]
        this.loading = false;
      });
    }
  }
}
