


















































































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {DimensionReductionResult} from "@/models/DimensionReductionResult";
import DimensionReductionResult2Plotly from "@/components/DimensionReductionResult2Plotly.vue";
import {DataAnalyseResponse} from "@/api/response/DataAnalyseResponse";

@Component({
  components: {
    DimensionReductionResult2Plotly
  }
})
export default class DataAnalysisSVD extends Vue {
  private inputFile: File | null = null;
  private dialog = false;
  private results: DimensionReductionResult[] = [
    {"name": "A", "dimensions": [18.0113363892349, -2.089599836758005, -0.851188433801076]},
    {"name": "B", "dimensions": [7.277622522306347, -1.3485033159010604, 0.9812442334915474]},
    {"name": "C", "dimensions": [13.433608469775058, 0.8871948405253793, 1.15844843420951]},
    {"name": "D", "dimensions": [18.482639985272723, -2.209470404736888, 0.6980648959406333]},
    {"name": "E", "dimensions": [13.578063901920654, 6.187698899694537, -0.3597029925446752]},
    {"name": "F", "dimensions": [17.115948505274943, -2.129565524441992, -0.1961486404876891]},
    {"name": "G", "dimensions": [9.08409325907569, -2.551172848006932, -0.8162155409847551]},
    {"name": "H", "dimensions": [20.52943047692829, 1.8511018457299229, 0.9949537016395386]},
    {"name": "I", "dimensions": [13.089559134176731, -1.5953986830399083, 2.2469347356981384]},
    {"name": "J", "dimensions": [5.846008013926818, -1.131719282144278, 0.38934690217063384]},
    {"name": "K", "dimensions": [12.276214953957195, -2.5326930609726004, -1.7823147626096318]},
    {"name": "L", "dimensions": [18.162365774410123, 3.5134627680929933, -0.05439483413888002]},
    {"name": "M", "dimensions": [11.269940491348123, -0.9978462422977141, -0.5854955426583229]},
    {"name": "N", "dimensions": [12.60578034502059, -1.825695884136153, -0.26530940115854823]},
    {"name": "O", "dimensions": [17.33721911401322, 2.480949943373723, -1.3797358938438395]}
  ];
  private dimensionCount = 1;

  private isActive = false;
  private startAnalysis = false;
  private loading = false;

  private send() {
    if (this.inputFile) {
      this.results = [];
      this.startAnalysis = true;
      this.loading = true;
      const data = new FormData();
      data.append("file", this.inputFile);
      data.append("dimension_count", `${this.dimensionCount + 2}`);
      Api.call<DataAnalyseResponse>(ApiDefConstants.DATA_SVD(), data).then((res: AxiosResponse<DataAnalyseResponse>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  }

  //private get dialogPlotLayout() {

  //}

}
