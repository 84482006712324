









































































import {Component} from 'vue-property-decorator';
import Base from "@/common/Base";
import ImageAnalysis from "@/components/ImageAnalysis.vue";
import LanguageMorphological from "@/components/LanguageMorphological.vue";
import LanguageSimilar from "@/components/LanguageSimilar.vue";
import LanguageCalc from "@/components/LanguageCalc.vue";
import DataAnalysisPCA from "@/components/DataAnalysisPCA.vue";
import DataAnalysisTSNE from "@/components/DataAnalysisTSNE.vue";
import DataAnalysisSVD from "@/components/DataAnalysisSVD.vue";
import DataAnalysisUMAP from "@/components/DataAnalysisUMAP.vue";

@Component({
  components: {
    ImageAnalysis,
    LanguageMorphological,
    LanguageSimilar,
    LanguageCalc,
    DataAnalysisPCA,
    DataAnalysisSVD,
    DataAnalysisTSNE,
    DataAnalysisUMAP,
  }
})
export default class Home extends Base {
  mounted() {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        this.$vuetify.goTo(hash);
      }, 10);
    }
  }
}
