import {Vue} from 'vue-property-decorator';

export default class Base extends Vue {
  private readonly mobileBreakpoints = ["xs", "sm"];

  protected isMobile(): boolean {
    return this.mobileBreakpoints.some(e => {
      return this.$vuetify.breakpoint.name === e;
    });
  }

  public isExtraSmall(): boolean {
    return this.$vuetify.breakpoint.name === "xs";
  }

  public isSmall(): boolean {
    return this.$vuetify.breakpoint.name === "sm";
  }

  public isMedium(): boolean {
    return this.$vuetify.breakpoint.name === "md";
  }

  public isLarge(): boolean {
    return this.$vuetify.breakpoint.name === "lg";
  }

  public isExtraLarge(): boolean {
    return this.$vuetify.breakpoint.name === "lg";
  }

}
