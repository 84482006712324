


























































































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {DimensionReductionResult} from "@/models/DimensionReductionResult";
import DimensionReductionResult2Plotly from "@/components/DimensionReductionResult2Plotly.vue";
import {DataAnalyseResponse} from "@/api/response/DataAnalyseResponse";

@Component({
  components: {
    DimensionReductionResult2Plotly
  }
})
export default class DataAnalysisTSNE extends Vue {
  private inputFile: File | null = null;
  private dialog = false;
  private results: DimensionReductionResult[] = [
    {"name": "A", "dimensions": [216.99844360351562, 0.4846157729625702]},
    {"name": "B", "dimensions": [-142.28684997558594, -175.5873260498047]},
    {"name": "C", "dimensions": [-99.0153579711914, -83.06383514404297]},
    {"name": "D", "dimensions": [230.22576904296875, 9.749714851379395]},
    {"name": "E", "dimensions": [172.94224548339844, 163.68995666503906]},
    {"name": "F", "dimensions": [229.6129150390625, -6.455641269683838]},
    {"name": "G", "dimensions": [-123.38414764404297, -152.8525848388672]},
    {"name": "H", "dimensions": [199.6642303466797, 133.0233612060547]},
    {"name": "I", "dimensions": [-100.80027770996094, -96.91656494140625]},
    {"name": "J", "dimensions": [-154.453369140625, -170.88629150390625]},
    {"name": "K", "dimensions": [-93.38923645019531, -144.7699432373047]},
    {"name": "L", "dimensions": [186.30322265625, 142.46749877929688]},
    {"name": "M", "dimensions": [-105.15394592285156, -138.488037109375]},
    {"name": "N", "dimensions": [-103.37579345703125, -120.123291015625]},
    {"name": "O", "dimensions": [173.8233642578125, 142.1680908203125]}
  ];
  private dimensionCount = 0;
  private perplexity = 2;

  private isActive = false;
  private startAnalysis = false;
  private loading = false;

  private send() {
    if (this.inputFile) {
      this.results = [];
      this.startAnalysis = true;
      this.loading = true;
      const data = new FormData();
      data.append("file", this.inputFile);
      data.append("dimension_count", `${this.dimensionCount + 2}`);
      data.append("perplexity", `${this.perplexity}`);
      Api.call<DataAnalyseResponse>(ApiDefConstants.DATA_T_SNE(), data).then((res: AxiosResponse<DataAnalyseResponse>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  }
}
