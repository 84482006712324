





















































import {Component, Vue} from 'vue-property-decorator';
import Api from "@/api/Api";
import ApiDefConstants from "@/constants/ApiDefConstants";
import {AxiosResponse} from "axios";
import {AnalyseResponseBase} from "@/api/response/AnalyseResponseBase";
import {AnalyseResultBase} from "@/models/AnalyseResultBase";

@Component({
  components: {}
})
export default class ImageAnalysis extends Vue {
  private inputFile: File | null = null;
  private blobUrl: string | ArrayBuffer | null = '/cat-2068462_640.jpg';
  private tableHeaders = [
    {text: 'Name', value: "name"},
    {text: 'Score', value: "score"}
  ];
  private results: AnalyseResultBase[] = [
    {name: "Siamese_cat", score: "0.9983334"},
    {name: "Egyptian_cat", score: "0.0013337109"},
    {name: "lynx", score: "0.00015162924"},
    {name: "malinois", score: "3.3684286e-05"},
    {name: "Norwegian_elkhound", score: "3.0298675e-05"},
  ];

  private startAnalysis = false;
  private loading = false;

  private onImagePicked(file: File) {
    if (file != undefined) {
      if (file.name.lastIndexOf('.') <= 0) {
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(file)
      fr.addEventListener('load', () => {
        this.blobUrl = fr.result;
      })
    } else {
      this.blobUrl = null;
    }
  }

  private sendImage() {
    if (this.inputFile) {
      this.results = [];
      this.startAnalysis = true;
      this.loading = true;
      const data = new FormData();
      data.append("file", this.inputFile);
      Api.call<AnalyseResponseBase>(ApiDefConstants.KERAS_IMAGE_ANALYSE(), data).then((res: AxiosResponse<AnalyseResponseBase>) => {
        this.results = res.data.analysis;
        this.loading = false;
      }).catch(() => {
        this.results = [
          {name: "エラー", score: "0"},
        ]
        this.loading = false;
      });
    }
  }
}
